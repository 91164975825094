import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/ProductsView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/product-create",
    name: "product_create",
    component: () => import("@/views/ProductCreateView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/product",
    name: "product_update",
    component: () => import("@/views/ProductUpdateView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/OrdersView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order",
    name: "order",
    component: () => import("@/views/OrderView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/SettingsView"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
