<template>
  <div class="sidebar">
    <div class="logo">
      <img src="@/assets/logo.svg" alt="" />
    </div>

    <ul class="nav flex-column">
      <li class="nav-item">
        <router-link
          class="nav-link d-flex align-items-center gap-2"
          :to="{ name: 'home' }">
          <i class="bi bi-layers"></i>
          Panel
        </router-link>
      </li>
      <li class="head">MODÜLLER</li>
      <li class="nav-item">
        <router-link
          class="nav-link d-flex align-items-center gap-2"
          :to="{ name: 'products' }">
          <i class="bi bi-archive"></i>
          Ürünler
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link d-flex align-items-center gap-2"
          :to="{ name: 'orders' }">
          <i class="bi bi-cart-check"></i>
          Siparişler
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link d-flex align-items-center gap-2"
          :to="{ name: 'settings' }">
          <i class="bi bi-gear"></i>
          Ayarlar
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SidebarComp",
};
</script>
<style lang="scss" scoped>
.sidebar {
  width: 250px;
  min-width: 250px;

  .logo {
    font-weight: 500;
    font-size: 1rem;
    color: #205679;
    font-family: "Poppins";
    padding: 1rem;
    border-bottom: 1px dashed #dddddd;
    height: 75px;
  }

  ul {
    list-style: none;
    margin-top: 1rem;

    a {
      border-radius: 10px;
      margin: 0 15px;
      margin-bottom: 10px;
      transition: 0.3s all ease-in-out;
      font-weight: 500;
      color: #205679;

      &:hover {
        background-color: #2055791f;
      }

      &.router-link-active {
        background-color: #75cf69;
        color: white;

        i {
          color: white;
        }
      }
    }

    li.head {
      margin-top: 18px;
      color: #205679;
      font-weight: 500;
      margin: 0 15px;
      margin-bottom: 10px;
    }
  }
}
</style>
