import axios from "axios";

export default {
  state: {
    products: [],
    product: {
      _id: "",
      title: "",
      slug: "",
      description: "",
      images: [],
      fee: {
        discount: 0,
        currently: 1,
      },
      quantity: {
        min_count: 1,
        max_count: 100,
      },
      stock: 1,
    },
  },
  getters: {
    _products(state) {
      return state.products;
    },
    _product(state) {
      return state.product;
    },
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
  },
  actions: {
    GET_PRODUCTS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/products`, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            context.commit("setProducts", res.data.products);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setProducts", []);
            reject(err?.response?.data || err);
          });
      });
    },
    GET_PRODUCT(context, slug) {
      return new Promise((resolve, reject) => {
        axios
          .get("/products/" + slug, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            context.commit("setProduct", res.data.product);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setProduct", {});
            reject(err?.response?.data || err);
          });
      });
    },
    CREATE_PRODUCT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/products`, payload, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_PRODUCT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/products`, payload, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    DELETE_PRODUCT(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/products`, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
            params: payload,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
