import { createStore } from "vuex";
import auth from "./modules/auth";
import products from "./modules/products";
import orders from "./modules/orders";
import axios from "axios";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    UPLOAD_FILE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`https://cdn.urunonerisi.com/api/images/upload`, payload, {
            headers: {
              Authorization: "CDN_SECURITY",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    GET_SETTINGS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/settings`, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
    UPDATE_SETTINGS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/settings`, payload, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
  modules: {
    auth,
    products,
    orders,
  },
});
