import axios from "axios";

export default {
  state: {
    orders: [],
    order: {
      _id: "",
      name: "",
      surname: "",
      phone: "",
      city: "",
      district: "",
      address: "",
      payment_method: "",
      items: [],
      items_fee: 0,
      tax_fee: 0,
      cargoo_fee: 0,
      total_fee: 0,
      status: "",
    },
  },
  getters: {
    _orders(state) {
      return state.orders;
    },
    _order(state) {
      return state.order;
    },
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload;
    },
    setOrder(state, payload) {
      state.order = payload;
    },
  },
  actions: {
    GET_ORDERS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/orders`, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            context.commit("setOrders", res.data.orders);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setOrders", []);
            reject(err?.response?.data || err);
          });
      });
    },
    GET_ORDER(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/orders/${payload}`, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
          })
          .then((res) => {
            context.commit("setOrder", res.data.order);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setOrder", {});
            reject(err?.response?.data || err);
          });
      });
    },
    DELETE_ORDER(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/orders`, {
            headers: {
              Authorization: "Bearer " + context.getters._token,
            },
            params: payload,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
