<template>
  <transition name="loader" mode="out-in">
    <Loader v-if="loader" />
    <router-view v-else-if="!loader && !_auth" v-slot="{ Component }">
      <keep-alive name="router" mode="out-in">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <div v-else class="app-wrapper">
      <transition name="blur" mode="out-in">
        <Sidebar />
      </transition>
      <div class="content-wrapper">
        <transition name="blur" mode="out-in">
          <Header />
        </transition>
        <router-view v-slot="{ Component }">
          <keep-alive name="router" mode="out-in">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </transition>
</template>

<script>
import Loader from "@/components/Loader";
import Sidebar from "@/components/shared/Sidebar";
import Header from "@/components/shared/Header";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    loader: true,
  }),
  components: {
    Loader,
    Sidebar,
    Header,
  },
  computed: {
    ...mapGetters(["_auth", "_token"]),
  },
  beforeCreate() {
    this.$store.dispatch("SET_TOKEN", localStorage.getItem("AUTH_TOKEN") || "");
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.auth && !this._token) {
        return next({ name: "login" });
      } else if (to.meta.auth && this._token) {
        return this.$store
          .dispatch("CHECK_AUTH")
          .then(() => next())
          .catch((err) => {
            next({ name: "login" });
            return this.$swal({
              icon: "error",
              text: err.message,
            });
          });
      }
      if (to.name === "login" && this._token) return next("/");
      next();
    });
  },
  mounted() {
    setTimeout(() => (this.loader = false), 500);
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  display: flex;
}
</style>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #e8fff3;
}

::-webkit-scrollbar-thumb {
  background: #86ec79;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #75cf69;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.content {
  padding: 30px;
}

.loader-enter-active,
.loader-leave-active {
  transition: opacity 0.5s ease;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}

.blur-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-to,
.blur-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.blur-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-active,
.blur-leave-active {
  transition: all 450ms ease-in-out;
}

.router-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.router-enter-to,
.router-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.router-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.router-enter-active,
.router-leave-active {
  transition: all 450ms ease-in-out;
}

.fade-enter-from {
  filter: blur(1px);
}
.fade-enter-to,
.fade-leave-from {
  filter: blur(0) grayscale(0);
}
.fade-leave-to {
  filter: blur(1px);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 200ms ease-in-out;
}

.manage-pages-enter-active,
.manage-pages-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.manage-pages-enter-from {
  transform: translateX(100%);
}

.manage-pages-enter {
  transform: translateX(-100%);
}

.manage-pages-leave-to {
  transform: translateX(100%);
}

.left-sidebar-enter-active,
.left-sidebar-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.left-sidebar-enter-from {
  transform: translateX(-100%);
}

.left-sidebar-enter {
  transform: translateX(100%);
}

.left-sidebar-leave-to {
  transform: translateX(-100%);
}

.right-sidebar-enter-active,
.right-sidebar-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.right-sidebar-enter-from {
  transform: translateX(100%);
}

.right-sidebar-enter {
  transform: translateX(-100%);
}

.right-sidebar-leave-to {
  transform: translateX(100%);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-down-enter-from {
  transform: translateY(-100%);
}

.slide-down-enter {
  transform: translateY(100%);
}

.slide-down-leave-to {
  transform: translateY(-100%);
}

.sepetledim-input {
  background-color: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  color: #5e6278 !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 10px 15px !important;
  border-radius: 0.75rem !important;
  font-weight: 500 !important;
  resize: none;

  &.xs {
    font-size: 14px;
    height: 34px;
    font-weight: 400 !important;
    padding: 5px 10px !important;
  }
}

.sepetledim-label {
  font-weight: 500 !important;
  color: #5e6278 !important;
}

.sepetledim-badge-primary {
  height: max-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 8px;
  color: #3e97ff;
  background-color: #f1faff;
}

.sepetledim-badge-success {
  height: max-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 8px;
  color: #50cd89;
  background-color: #e8fff3;
}

.sepetledim-badge-danger {
  height: max-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 8px;
  color: #cd5050;
  background-color: #ffe8e8;
}

.sepetledim-btn-primary {
  height: max-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  color: #3e97ff;
  background-color: #f1faff;
  border: 1px solid #f1faff;
  text-decoration: none;
  outline: none;
  transition: 0.2s all ease-in-out;

  &:hover {
    border-color: #3e97ff;
  }

  &.active {
    border-color: #3e97ff;
  }

  &.md {
    font-size: 1.1rem;
  }

  &.lg {
    font-size: 1.2rem;
  }

  &.sm {
    font-size: 0.9rem;
  }

  &.xl {
    font-size: 1.3rem;
  }
}

.sepetledim-btn-danger {
  height: max-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  color: #cd5050;
  background-color: #ffe8e8;
  border: 1px solid #ffe8e8;
  text-decoration: none;
  outline: none;
  transition: 0.2s all ease-in-out;

  &:hover {
    border-color: #cd5050;
  }

  &.active {
    border-color: #cd5050;
  }

  &.md {
    font-size: 1.1rem;
  }

  &.lg {
    font-size: 1.2rem;
  }

  &.sm {
    font-size: 0.9rem;
  }

  &.xl {
    font-size: 1.3rem;
  }
}

.sepetledim-btn-success {
  height: max-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  color: #50cd89;
  background-color: #e8fff3;
  border: 1px solid #e8fff3;
  text-decoration: none;
  outline: none;
  transition: 0.2s all ease-in-out;

  &:hover {
    border-color: #50cd89;
  }

  &.active {
    border-color: #50cd89;
  }

  &.md {
    font-size: 1.1rem;
  }

  &.lg {
    font-size: 1.2rem;
  }

  &.sm {
    font-size: 0.9rem;
  }

  &.xl {
    font-size: 1.3rem;
  }
}

.sepetledim-btn-outline-light {
  height: max-content;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  color: #606060;
  background-color: #fff;
  border: 1px solid #dedede;
  text-decoration: none;
  outline: none;
  transition: 0.2s all ease-in-out;

  &:hover {
    color: #3e97ff;
    border-color: #3e97ff;
  }

  &.active {
    color: #3e97ff;
    border-color: #3e97ff;
  }

  &.md {
    font-size: 1.1rem;
  }

  &.lg {
    font-size: 1.2rem;
  }

  &.sm {
    font-size: 0.9rem;
  }

  &.xl {
    font-size: 1.3rem;
  }
}
</style>
