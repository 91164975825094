<template>
  <div class="header">
    <ul>
      <li class="sepetledim-btn-danger">
        <a class="dropdown-item" href="#" @click.prevent="logout()">
          <i class="bi bi-box-arrow-right"></i>
          Güvenli Çıkış
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderComp",
  data: () => ({}),
  computed: {
    ...mapGetters(["_admin"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$router.push({ name: "login" });
      this.$swal({
        icon: "success",
        text: "Oturumunuz Sonlandırılmıştır, İyi günler.",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 75px;
  width: calc(100vw - 250px);
  border-bottom: 1px dashed #dddddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0 15px;
    pointer-events: all;

    li {
      border-radius: 10px;
      transition: 0.3s all ease-in-out;
      font-weight: 700;
      padding: 5px 15px;
      margin-bottom: 10px;

      i {
        margin-right: 8px;
      }
    }
  }

  .personel_information {
    display: flex;
    flex-direction: column;
    text-align: end;
    justify-content: center;

    span {
      font-weight: 600;
      color: #000000;
    }

    span:nth-child(2) {
      color: #75cf69;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .buttons {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    gap: 10px;

    button {
      border-radius: 12px;
      background-color: #ffffff;
      outline: none;
      border: none;
      width: 45px;
      height: 45px;
      transition: 0.3s all ease-in-out;
      border: 1px solid #ffffff;

      i {
        color: #205679;
      }

      &:hover {
        background-color: #dddddd;
      }

      &:active {
        border-color: #c2c2c2;
      }
    }
  }
}
</style>
